<template>
  <div id="content-wrapper" style="background: #ffffff">
    <div class="container">
      <div class="row">
        <div class="project-page col-sm-12 mar-top-0" style="text-align: left">
          <h2>Elementals Trait Rarity</h2>
          <div class="row">
            <div
              v-for="title in titles"
              :key="title"
              class="rarities-list col-md-6 col-lg-4"
            >
              <div class="info-container">
                <h5>{{ title }}</h5>
                <p v-for="item in itemsForKey(title)" :key="item[0]">
                  {{ item[0] }} : {{ item[1] }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ElementalStats from '@/files/ElementalStats.json';

export default {
  data() {
    return {
      rarities: undefined,
      titles: [],
    };
  },
  mounted() {
    this.rarities = ElementalStats;
    this.titles = Object.keys(ElementalStats);
  },
  methods: {
    itemsForKey(key) {
      const entries = Object.entries(this.rarities[key]);
      entries.sort((a, b) => {
        return parseInt(b[1]) - parseInt(a[1]);
      });
      return entries;
    },
  },
};
</script>
